import { __decorate, __metadata } from "tslib";
import { html, LitElement } from 'lit';
import { property, query } from 'lit/decorators.js';
import styles from './arc-radio-group.styles.js';
/**
 * @slot default - The default slot where radio controls are placed.
 * @slot label - The radio group label. Alternatively, you can use the label prop.
 *
 * @event arc-change - Emitted when a control's checked state changes.
 *
 * @ssr - True
 */
export default class ArcRadioGroup extends LitElement {
  constructor() {
    super(...arguments);
    /** Lays out the radio buttons horizontally. */
    this.row = false;
  }
  /* When tabbing into the fieldset, make sure it lands on the checked radio */
  handleFocusIn() {
    const slottedChildren = this.defaultSlot.assignedElements({
      flatten: true
    });
    const checkedRadio = [...slottedChildren].find(el => el.tagName === 'ARC-RADIO' && el.checked);
    checkedRadio === null || checkedRadio === void 0 ? void 0 : checkedRadio.focus();
  }
  render() {
    return html`
      <div id="main">
        <label id="label">
          <slot name="label">${this.label}</slot>
        </label>
        <div id="radioGroup" role="radiogroup" @focusin=${this.handleFocusIn}>
          <slot></slot>
        </div>
      </div>
    `;
  }
}
/** @internal */
ArcRadioGroup.tag = 'arc-radio-group';
ArcRadioGroup.styles = styles;
__decorate([query('slot:not([name])'), __metadata("design:type", HTMLSlotElement)], ArcRadioGroup.prototype, "defaultSlot", void 0);
__decorate([property({
  type: String
}), __metadata("design:type", String)], ArcRadioGroup.prototype, "label", void 0);
__decorate([property({
  type: Boolean
}), __metadata("design:type", Boolean)], ArcRadioGroup.prototype, "row", void 0);
