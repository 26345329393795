import { Injectable, inject } from '@angular/core';
import { ExceptionService } from '@core/services/exception.service';
import {
  HttpRequest,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  private readonly exceptionService = inject(ExceptionService);

  public intercept<T>(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    return next
      .handle(request)
      .pipe(this.exceptionService.handleHttpError<T>());
  }
}
