import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthStore } from '@core/stores/auth.store';
import { NotificationService } from '@core/services/notification.service';
import { tap } from 'rxjs';

export const PROJECT_ROLE_GUARD: CanActivateFn = (route) => {
  const authStore = inject(AuthStore);
  const notification = inject(NotificationService);
  const router = inject(Router);
  return authStore.canAccessProject(route.params.projectNumber).pipe(
    tap((canAccessProject) => {
      if (!canAccessProject) {
        notification.error('You do not have access to this project');
        router.navigate(['projects']);
      }
    })
  );
};
