var PhIconList_1;
import { __decorate, __metadata } from "tslib";
/* GENERATED FILE */
import { html, svg, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';
import { FONT_SIZES } from '../../../internal/constants/styleConstants.js';
import styles from '../../icon/icon.styles.js';
/**
 * @cssproperty --icon-color - Set the color of the icon.
 *
 * @ssr - True
 */
let PhIconList = PhIconList_1 = class PhIconList extends LitElement {
  constructor() {
    super(...arguments);
    /** Set the size of the icon. */
    this.size = FONT_SIZES.medium;
    /** Set the rotation of the icon. */
    this.rotation = 0;
  }
  render() {
    return html`
      <svg
        id="main"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        role=${ifDefined(this.label ? 'img' : undefined)}
        aria-label=${ifDefined(this.label || undefined)}
        aria-hidden=${ifDefined(this.label ? undefined : 'true')}
        style=${styleMap({
      transform: this.rotation ? `rotate(${this.rotation}deg)` : null,
      height: `var(--arc-font-size-${this.size})`,
      width: `var(--arc-font-size-${this.size})`
    })}
      >
        ${PhIconList_1.svg}
      </svg>
    `;
  }
};
/** @internal */
PhIconList.tag = 'ph-icon-list';
/** @internal */
PhIconList.styles = styles;
/** @internal */
PhIconList.svg = svg`<path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z"/>`;
__decorate([property({
  type: String
}), __metadata("design:type", String)], PhIconList.prototype, "label", void 0);
__decorate([property({
  type: String,
  reflect: true
}), __metadata("design:type", String)], PhIconList.prototype, "size", void 0);
__decorate([property({
  type: Number
}), __metadata("design:type", Number)], PhIconList.prototype, "rotation", void 0);
PhIconList = PhIconList_1 = __decorate([customElement('ph-icon-list')], PhIconList);
export default PhIconList;
