import { InjectionToken } from '@angular/core';

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type RefreshTokenConfig = Map<string, Method>;

export const REFRESH_TOKEN_CONFIG = new InjectionToken<RefreshTokenConfig>(
  'REFRESH_TOKEN_CONFIG',
  {
    providedIn: 'root',
    factory: () =>
      new Map([
        ['/projects', 'GET'],
        [`/projects/*`, 'PUT'],
      ]),
  }
);
