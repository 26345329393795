import { __decorate, __metadata } from "tslib";
import { LitElement } from 'lit';
import { html, literal } from 'lit/static-html.js';
import { property, query } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { classMap } from 'lit/directives/class-map.js';
import { FormController } from '../../internal/form-control.js';
import { INPUT_SIZES, THEME_COLORS } from '../../internal/constants/styleConstants.js';
import { BUTTON_TYPES } from './constants/ButtonConstants.js';
import styles from './arc-button.styles.js';
import '../spinner/arc-spinner.js';
/**
 * @slot default - The button's label.
 * @slot prefix - Used to prepend an icon or similar element to the button.
 * @slot suffix - Used to append an icon or similar element to the button.
 *
 * @cssproperty --min-width - Set the min width of the button.
 * @cssproperty --btn-color - Overwrite the font color of the button.
 * @cssproperty --btn-background - Overwrite the background color of the button.
 *
 * @ssr - True
 */
export default class ArcButton extends LitElement {
  constructor() {
    super(...arguments);
    /** @internal - Controller used to recognize form controls located inside a shadow root. */
    this.formController = new FormController(this);
    /** Set the color of the button. */
    this.color = THEME_COLORS.default;
    /** Set the size of the button. */
    this.size = INPUT_SIZES.medium;
    /** Set the type of the button. */
    this.type = BUTTON_TYPES.filled;
    /** Draws the button in an active state. */
    this.active = false;
    /** Draws the button in a disabled state. */
    this.disabled = false;
    /** Draws the button in a loading state. */
    this.loading = false;
    /** Indicates if activating the button should submit the form. Ignored when href is set. */
    this.submit = false;
  }
  /* Simulates a click on the button. */
  click() {
    this.button.click();
  }
  /* Sets focus on the button. */
  focus(options) {
    this.button.focus(options);
  }
  /* Removes focus from the button. */
  blur() {
    this.button.blur();
  }
  /* Handle the click of the button */
  _handleClick(event) {
    if (this.disabled || this.loading) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    /* Submit the surrounding form with the formSubmitController class. */
    if (this.submit) {
      this.formController.submit();
    }
  }
  render() {
    const isLink = !!this.href;
    const tag = isLink ? literal`a` : literal`button`;
    return html`
      <${tag}
        id="main"
        class=${classMap({
      button: true,
      'button--small': this.size === INPUT_SIZES.small,
      'button--medium': this.size === INPUT_SIZES.medium,
      'button--large': this.size === INPUT_SIZES.large,
      'button--default': this.color === THEME_COLORS.default,
      'button--primary': this.color === THEME_COLORS.primary,
      'button--secondary': this.color === THEME_COLORS.secondary,
      'button--error': this.color === THEME_COLORS.error,
      'button--warning': this.color === THEME_COLORS.warning,
      'button--info': this.color === THEME_COLORS.info,
      'button--success': this.color === THEME_COLORS.success,
      'button--filled': this.type === BUTTON_TYPES.filled,
      'button--outlined': this.type === BUTTON_TYPES.outlined,
      'button--tab': this.type === BUTTON_TYPES.tab,
      'button--active': this.active,
      'button--disabled': this.disabled,
      'button--loading': this.loading
    })}
        ?disabled=${ifDefined(isLink ? undefined : this.disabled)}
        type=${this.submit ? 'submit' : 'button'}
        name=${ifDefined(isLink ? undefined : this.name)}
        value=${ifDefined(isLink ? undefined : this.value)}
        href=${ifDefined(this.href || undefined)}
        target=${ifDefined(this.target || undefined)}
        download=${ifDefined(this.download || undefined)}
        rel=${ifDefined(this.target ? 'noreferrer noopener' : undefined)}
        role="button"
        aria-disabled=${this.disabled ? 'true' : 'false'}
        tabindex=${this.disabled ? '-1' : '0'}
        @click=${this._handleClick}
      >
        <slot id="prefix" name="prefix"></slot>
        <slot id="label"></slot>
        <slot id="suffix" name="suffix"></slot>
        ${when(this.loading, () => html`<arc-spinner id="loader"></arc-spinner>`)}
      </${tag}>
    `;
  }
}
/** @internal */
ArcButton.tag = 'arc-button';
ArcButton.styles = styles;
__decorate([query('#main'), __metadata("design:type", Object)], ArcButton.prototype, "button", void 0);
__decorate([property({
  type: String,
  reflect: true
}), __metadata("design:type", String)], ArcButton.prototype, "color", void 0);
__decorate([property({
  type: String,
  reflect: true
}), __metadata("design:type", String)], ArcButton.prototype, "size", void 0);
__decorate([property({
  type: String,
  reflect: true
}), __metadata("design:type", String)], ArcButton.prototype, "type", void 0);
__decorate([property({
  type: String
}), __metadata("design:type", String)], ArcButton.prototype, "name", void 0);
__decorate([property({
  type: String
}), __metadata("design:type", String)], ArcButton.prototype, "value", void 0);
__decorate([property({
  type: String
}), __metadata("design:type", String)], ArcButton.prototype, "href", void 0);
__decorate([property({
  type: String
}), __metadata("design:type", String)], ArcButton.prototype, "target", void 0);
__decorate([property({
  type: String
}), __metadata("design:type", String)], ArcButton.prototype, "download", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
}), __metadata("design:type", Boolean)], ArcButton.prototype, "active", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
}), __metadata("design:type", Boolean)], ArcButton.prototype, "disabled", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
}), __metadata("design:type", Boolean)], ArcButton.prototype, "loading", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
}), __metadata("design:type", Boolean)], ArcButton.prototype, "submit", void 0);
