import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { LOAD_PROJECTS } from '@core/guards/load-projects.guard';
import { PROJECT_ROLE_GUARD } from '@core/guards/project-role.guard';
import { LOAD_TYPES } from '@core/guards/load-types.guard';
import {
  CLEAR_SELECTED_PROJECT_GUARD,
  SELECTED_PROJECT_GUARD,
} from '@core/guards/selected-project.guard';

export const appRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        '@landing-page/containers/landing-page/landing-page.component'
      ).then((c) => c.LandingPageComponent),
  },
  {
    path: '',
    canActivateChild: [MsalGuard, LOAD_TYPES, LOAD_PROJECTS],
    children: [
      {
        path: 'projects',
        loadComponent: () =>
          import(
            '@projects/containers/projects-page/projects-page.component'
          ).then((c) => c.ProjectsPageComponent),
      },
      {
        path: 'project/setup',
        canActivate: [CLEAR_SELECTED_PROJECT_GUARD],
        loadComponent: () =>
          import(
            '@setup/containers/setup-container/setup-container.component'
          ).then((c) => c.SetupContainerComponent),
        children: [
          {
            path: '',
            title: 'Key Project Details',
            children: [
              {
                path: '',
                outlet: 'setup-sidebar',
                loadComponent: () =>
                  import(
                    '@setup/containers/setup-container/project-search-aside/project-search-aside.component'
                  ).then((c) => c.ProjectSearchAsideComponent),
              },
              {
                path: '',
                outlet: 'setup-content',
                loadComponent: () =>
                  import(
                    '@setup/containers/setup-container/key-project-details/key-project-details.component'
                  ).then((c) => c.KeyProjectDetailsComponent),
              },
            ],
          },
        ],
      },
      {
        path: 'project/:projectNumber',
        pathMatch: 'prefix',
        canActivateChild: [PROJECT_ROLE_GUARD, SELECTED_PROJECT_GUARD],
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                '@landing-page/containers/project-landing-page/project-landing-page.component'
              ).then((c) => c.ProjectLandingPageComponent),
          },
          {
            path: 'details',
            title: 'Key Project Details',
            loadComponent: () =>
              import(
                '@setup/containers/setup-container/setup-container.component'
              ).then((c) => c.SetupContainerComponent),
            children: [
              {
                path: '',
                outlet: 'setup-sidebar',
                loadComponent: () =>
                  import(
                    '@setup/containers/setup-container/project-edit-aside/project-edit-aside.component'
                  ).then((c) => c.ProjectEditAsideComponent),
              },
              {
                path: '',
                outlet: 'setup-content',
                loadComponent: () =>
                  import(
                    '@setup/containers/setup-container/key-project-details/key-project-details.component'
                  ).then((c) => c.KeyProjectDetailsComponent),
              },
            ],
          },
          {
            path: 'team',
            loadComponent: () =>
              import(
                '@setup/containers/setup-container/setup-container.component'
              ).then((c) => c.SetupContainerComponent),
            title: 'Project team',
            children: [
              {
                path: '',
                outlet: 'setup-sidebar',
                loadComponent: () =>
                  import(
                    '@setup/containers/setup-container/user-aside/user-aside.component'
                  ).then((c) => c.UserAsideComponent),
              },
              {
                path: '',
                outlet: 'setup-content',
                loadComponent: () =>
                  import(
                    '@setup/containers/setup-container/user-management/user-management.component'
                  ).then((c) => c.UserManagementComponent),
              },
            ],
          },
          {
            path: 'outputs',
            loadComponent: () =>
              import(
                '@outputs/containers/outputs-page/outputs-page.component'
              ).then((c) => c.OutputsPageComponent),
          },
        ],
      },
    ],
  },
  { path: '', redirectTo: '', pathMatch: 'full' },
];
