/* Emits a custom event with more convenient defaults. */
function emit(el, name, options) {
  const event = new CustomEvent(name, Object.assign({
    bubbles: true,
    cancelable: false,
    composed: true,
    detail: {}
  }, options));
  el.dispatchEvent(event);
  return event;
}
/*
Waits for a specific event to be emitted from an element.
Ignores events that bubble up from child elements.
*/
function waitForEvent(el, eventName) {
  return new Promise(resolve => {
    function done(event) {
      if (event.target === el) {
        el.removeEventListener(eventName, done);
        resolve();
      }
    }
    el.addEventListener(eventName, done);
  });
}
export { emit, waitForEvent };
