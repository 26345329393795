import { __decorate, __metadata } from "tslib";
import { LitElement } from 'lit';
import { html, literal } from 'lit/static-html.js';
import { property, query } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { classMap } from 'lit/directives/class-map.js';
import styles from './arc-icon-button.styles.js';
import '../ph-icon/lightning/ph-icon-lightning.js';
import '../spinner/arc-spinner.js';
/**
 * @slot default - The button's label.
 *
 * @cssproperty --icon-color - Overwrite the color of the icon.
 *
 * @ssr - True
 */
export default class ArcIconButton extends LitElement {
  constructor() {
    super(...arguments);
    /** A description that gets read by screen readers and other assistive devices. For optimal accessibility, you should always include a label that describes what the icon button does. */
    this.label = '';
    /** Draws the button in an active state. */
    this.active = false;
    /** Draws the button in a disabled state. */
    this.disabled = false;
    /** Draws the button in a loading state. */
    this.loading = false;
  }
  /* Simulates a click on the button. */
  click() {
    this.button.click();
  }
  /* Sets focus on the button. */
  focus(options) {
    this.button.focus(options);
  }
  /* Removes focus from the button. */
  blur() {
    this.button.blur();
  }
  /* Handle the click of the button */
  _handleClick(event) {
    if (this.disabled || this.loading) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
  render() {
    const isLink = !!this.href;
    const tag = isLink ? literal`a` : literal`button`;
    return html`
      <${tag}
        id="main"
        class=${classMap({
      button: true,
      'button--active': this.active,
      'button--disabled': this.disabled,
      'button--loading': this.loading
    })}
        ?disabled=${ifDefined(isLink ? undefined : this.disabled)}
        type="button"
        href=${ifDefined(this.href || undefined)}
        target=${ifDefined(this.target || undefined)}
        download=${ifDefined(this.download || undefined)}
        rel=${ifDefined(this.target ? 'noreferrer noopener' : undefined)}
        role="button"
        aria-disabled=${this.disabled ? 'true' : 'false'}
        aria-label=${this.label}
        tabindex=${this.disabled ? '-1' : '0'}
        @click=${this._handleClick}
      >
        <span id="iconWrapper" aria-hidden="true">
          <span id="icon">
            <slot name="icon">
              <ph-icon-lightning></ph-icon-lightning>
            </slot>
          </span>
          ${when(this.loading, () => html`<arc-spinner id="loader"></arc-spinner>`)}
        </span>
        <span id="action"><slot></slot></span>
      </${tag}>
    `;
  }
}
/** @internal */
ArcIconButton.tag = 'arc-icon-button';
ArcIconButton.styles = styles;
__decorate([query('#main'), __metadata("design:type", Object)], ArcIconButton.prototype, "button", void 0);
__decorate([property({
  type: String
}), __metadata("design:type", String)], ArcIconButton.prototype, "href", void 0);
__decorate([property({
  type: String
}), __metadata("design:type", String)], ArcIconButton.prototype, "target", void 0);
__decorate([property({
  type: String
}), __metadata("design:type", String)], ArcIconButton.prototype, "download", void 0);
__decorate([property({
  type: String
}), __metadata("design:type", String)], ArcIconButton.prototype, "label", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
}), __metadata("design:type", Boolean)], ArcIconButton.prototype, "active", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
}), __metadata("design:type", Boolean)], ArcIconButton.prototype, "disabled", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
}), __metadata("design:type", Boolean)], ArcIconButton.prototype, "loading", void 0);
