import { APP_INITIALIZER, StaticProvider } from '@angular/core';
import { Router } from '@angular/router';

import { RuntimeConfigurationModel } from '@core/models/runtime-configuration.model';

import {
  BrowserOptions,
  TraceService,
  BrowserTracing,
  init,
} from '@sentry/angular-ivy';

export function initSentry(config: RuntimeConfigurationModel): void {
  if (config.sentry.enable) {
    const { options, integrations } = config.sentry;
    const browserOptions: BrowserOptions = { ...options, integrations: [] };
    const intergrationObjects: BrowserOptions['integrations'] = [];
    for (const i of Object.keys(integrations)) {
      switch (i) {
        case 'browser-tracing': {
          const browserTracing = new BrowserTracing(integrations[i]);
          intergrationObjects.push(browserTracing);
          break;
        }
      }
    }
    browserOptions.integrations = intergrationObjects;
    init(browserOptions);
  }
}

export function createProviders(): StaticProvider[] {
  return [
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ];
}
