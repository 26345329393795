import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ProjectsStore } from '@core/stores/projects.store';
import { ProjectSetupStore } from '@setup/stores/project-setup.store';
import { filterSuccess } from '@core/utils/http';
import { map } from 'rxjs';

export const SELECTED_PROJECT_GUARD: CanActivateFn = (route) => {
  const projectsStore = inject(ProjectsStore);
  const setupStore = inject(ProjectSetupStore);
  const projectNumber = route.params.projectNumber;
  return projectsStore.selectByProjectNumber(projectNumber).pipe(
    filterSuccess(),
    map((project) => {
      setupStore.setProject(project.data.details);
      return true;
    })
  );
};

export const CLEAR_SELECTED_PROJECT_GUARD: CanActivateFn = (route) => {
  const setupStore = inject(ProjectSetupStore);
  setupStore.setProject(null);
  return true;
};
