import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ProjectsStore } from '@core/stores/projects.store';

export const LOAD_PROJECTS: CanActivateFn = () => {
  const projectsStore = inject(ProjectsStore);
  const navigation = inject(Router).getCurrentNavigation();
  const { invalidateCahce = false } = navigation?.extras?.state ?? {};
  projectsStore.load(invalidateCahce);
  return true;
};
