/* Class used to automatically append arc form element values to the FormData object that's used to submit the form. */
export class FormController {
  constructor(host, options) {
    (this.host = host).addController(this);
    this.options = Object.assign({
      form: input => input.closest('form'),
      name: input => input.name,
      value: input => input.value,
      disabled: input => input.disabled,
      reportValidity: input => typeof input.reportValidity === 'function' ? input.reportValidity() : true
    }, options);
    this.handleFormData = this.handleFormData.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }
  hostConnected() {
    this.form = this.options.form(this.host);
    if (this.form) {
      this.form.addEventListener('formdata', this.handleFormData);
      this.form.addEventListener('submit', this.handleFormSubmit);
    }
  }
  hostDisconnected() {
    if (this.form) {
      this.form.removeEventListener('formdata', this.handleFormData);
      this.form.removeEventListener('submit', this.handleFormSubmit);
      this.form = undefined;
    }
  }
  handleFormData(event) {
    const disabled = this.options.disabled(this.host);
    const name = this.options.name(this.host);
    const value = this.options.value(this.host);
    if (!disabled && !!name && !!value) {
      if (Array.isArray(value)) {
        value.forEach(val => {
          event.formData.append(name, val.toString());
        });
      } else {
        event.formData.append(name, value.toString());
      }
    }
  }
  handleFormSubmit(event) {
    const disabled = this.options.disabled(this.host);
    const {
      reportValidity
    } = this.options;
    if (this.form && !this.form.noValidate && !disabled && !reportValidity(this.host)) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
  /*
  Calling form.submit() seems to bypass the submit event and constraint validation.
  Instead, we can inject a native submit button into the form, click it, then remove it to simulate a standard form submission.
  */
  submit() {
    if (this.form) {
      const button = document.createElement('button');
      this.form.append(button);
      button.click();
      button.remove();
    }
  }
}
