import { Inject, Injectable } from '@angular/core';
import { AuthStore } from '@core/stores/auth.store';
import {
  HttpRequest,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import {
  REFRESH_TOKEN_CONFIG,
  RefreshTokenConfig,
} from '@core/tokens/refesh-token-config.token';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';
import { RuntimeConfigurationModel } from '@core/models/runtime-configuration.model';

@Injectable({ providedIn: 'root' })
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly authStore: AuthStore,
    @Inject(REFRESH_TOKEN_CONFIG)
    private readonly refeshTokenConfig: RefreshTokenConfig,
    @Inject(RUNTIME_CONFIGURATION)
    private readonly runtimeConfiguration: RuntimeConfigurationModel
  ) {}

  public intercept<T>(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    const requestUrl = request.url.split('?')[0];
    const requestMethod = request.method;
    const refeshUrlsAndMethods = this.refeshTokenConfig.entries();
    for (const [url, method] of refeshUrlsAndMethods) {
      const matchUrl = `${this.runtimeConfiguration.apiUrl}${url}`;
      if (new RegExp(matchUrl).test(requestUrl) && method === requestMethod) {
        return this.authStore
          .refreshToken()
          .pipe(switchMap(() => next.handle(request)));
      }
    }
    return next.handle(request);
  }
}
