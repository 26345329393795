import { __decorate, __metadata } from "tslib";
import { html, LitElement } from 'lit';
import { property, query } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { classMap } from 'lit/directives/class-map.js';
import { watch } from '../../internal/watch.js';
import { emit } from '../../internal/event.js';
import { FormController } from '../../internal/form-control.js';
import { ARC_EVENTS } from '../../internal/constants/eventConstants.js';
import styles from './arc-radio.styles.js';
/**
 * @slot default - The radio's label.
 *
 * @event arc-change - Emitted when the control's checked state changes.
 *
 * @ssr - True
 */
export default class ArcRadio extends LitElement {
  constructor() {
    super(...arguments);
    /** @internal - Controller used to recognize form controls located inside a shadow root. */
    /* @ts-expect-error - Controller used to hook the component to the formData */
    this.formController = new FormController(this, {
      value: control => control.checked ? control.value : undefined
    });
    /** Draws the component in a disabled state. */
    this.disabled = false;
    /** Draws the component in a checked state. */
    this.checked = false;
    /**
     * This will be true when the control is in an invalid state. Validity in radios is determined by the message provided
     * by the `setCustomValidity` method.
     */
    this.invalid = false;
  }
  firstUpdated() {
    this.updateComplete.then(() => {
      const radios = this.getAllRadios();
      const checkedRadio = radios.find(radio => radio.checked);
      /* Set the tabindex of all radios to -1. */
      radios.forEach(radio => {
        radio.input.tabIndex = -1;
      });
      /* Make sure that at least one radio gets the tabindex of 0. */
      if (checkedRadio && !checkedRadio.disabled) {
        checkedRadio.input.tabIndex = 0;
      } else if (radios.length > 0) {
        const enabledRadios = this.getAllRadios({
          includeDisabled: false
        });
        enabledRadios[0].input.tabIndex = 0;
      }
    });
  }
  /* Enable/disable the editor when the disabled property changes */
  handleDisabledChange() {
    /* Disabled form controls are always valid, so we need to recheck validity when the state changes */
    this.input.disabled = this.disabled;
    this.invalid = !this.input.checkValidity();
  }
  handleCheckedChange() {
    /* If the radio gets checked, remove the checked status from sibling radio buttons. */
    if (this.checked) {
      this.input.tabIndex = 0;
      this.getSiblingRadios().forEach(radio => {
        radio.input.tabIndex = -1;
        radio.checked = false;
      });
    }
  }
  getAllRadios(options = {
    includeDisabled: true
  }) {
    const radioGroup = this.closest('arc-radio-group');
    const {
      includeDisabled
    } = options;
    /* Radios must be part of a radio group. */
    if (!radioGroup) return [this];
    return [...radioGroup.querySelectorAll('arc-radio')].filter(radio => {
      if (radio.name !== this.name) return false;
      /* Are disabled items included? return true, else false. */
      return !(!includeDisabled && radio.disabled);
    });
  }
  getSiblingRadios() {
    return this.getAllRadios().filter(radio => radio !== this);
  }
  /* Simulates a click on the radio. */
  click() {
    this.input.click();
  }
  /* Sets focus on the radio. */
  focus(options) {
    this.input.focus(options);
  }
  /* Removes focus from the radio. */
  blur() {
    this.input.blur();
  }
  /** Checks for validity and shows the browser's validation message if the control is invalid. */
  reportValidity() {
    return this.input.reportValidity();
  }
  /** Sets a custom validation message. If `message` is not empty, the field will be considered invalid. */
  setCustomValidity(message) {
    this.input.setCustomValidity(message);
    this.invalid = !this.input.checkValidity();
  }
  /* Handle the click of the radio */
  _handleClick() {
    if (!this.checked) {
      this.checked = true;
      emit(this, ARC_EVENTS.change);
    }
  }
  handleKeyDown(event) {
    /* Move the selection when pressing down, up, left or right. */
    if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
      const radios = this.getAllRadios({
        includeDisabled: false
      });
      const incr = ['ArrowUp', 'ArrowLeft'].includes(event.key) ? -1 : 1;
      let index = radios.indexOf(this) + incr;
      if (index < 0) index = radios.length - 1;
      if (index > radios.length - 1) index = 0;
      /* Remove the checked state of all radio buttons. */
      this.getAllRadios().forEach(radio => {
        radio.checked = false;
        radio.input.tabIndex = -1;
      });
      /* Set focus on the radio. */
      radios[index].input.focus();
      radios[index].checked = true;
      radios[index].input.tabIndex = 0;
      emit(radios[index], ARC_EVENTS.change);
      event.preventDefault();
    }
  }
  render() {
    return html`
      <label
        id="main"
        class=${classMap({
      radio: true,
      'radio--checked': this.checked,
      'radio--disabled': this.disabled
    })}
        @keydown=${this.handleKeyDown}
      >
        <input
          type="radio"
          name=${ifDefined(this.name || undefined)}
          .value=${ifDefined(this.value || undefined)}
          .checked=${live(this.checked)}
          .disabled=${this.disabled}
          aria-checked=${this.checked ? 'true' : 'false'}
          aria-disabled=${this.disabled ? 'true' : 'false'}
          @click=${this._handleClick}
        />
        <span id="control">
          <span id="icon">
            <svg
              class="bg"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="RadioButtonUncheckedIcon"
            >
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
              ></path>
            </svg>
            <svg
              class="fill"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="RadioButtonCheckedIcon"
            >
              <path
                d="M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z"
              ></path>
            </svg>
          </span>
        </span>
        <span id="label"><slot></slot></span>
      </label>
    `;
  }
}
/** @internal */
ArcRadio.tag = 'arc-radio';
ArcRadio.styles = styles;
__decorate([query('input[type="radio"]'), __metadata("design:type", HTMLInputElement)], ArcRadio.prototype, "input", void 0);
__decorate([property({
  type: String
}), __metadata("design:type", String)], ArcRadio.prototype, "name", void 0);
__decorate([property({
  type: String
}), __metadata("design:type", String)], ArcRadio.prototype, "value", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
}), __metadata("design:type", Boolean)], ArcRadio.prototype, "disabled", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
}), __metadata("design:type", Boolean)], ArcRadio.prototype, "checked", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
}), __metadata("design:type", Object)], ArcRadio.prototype, "invalid", void 0);
__decorate([watch('disabled', {
  waitUntilFirstUpdate: true
}), __metadata("design:type", Function), __metadata("design:paramtypes", []), __metadata("design:returntype", void 0)], ArcRadio.prototype, "handleDisabledChange", null);
__decorate([watch('checked', {
  waitUntilFirstUpdate: true
}), __metadata("design:type", Function), __metadata("design:paramtypes", []), __metadata("design:returntype", void 0)], ArcRadio.prototype, "handleCheckedChange", null);
