var PhIconLightning_1;
import { __decorate, __metadata } from "tslib";
/* GENERATED FILE */
import { html, svg, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';
import { FONT_SIZES } from '../../../internal/constants/styleConstants.js';
import styles from '../../icon/icon.styles.js';
/**
 * @cssproperty --icon-color - Set the color of the icon.
 *
 * @ssr - True
 */
let PhIconLightning = PhIconLightning_1 = class PhIconLightning extends LitElement {
  constructor() {
    super(...arguments);
    /** Set the size of the icon. */
    this.size = FONT_SIZES.medium;
    /** Set the rotation of the icon. */
    this.rotation = 0;
  }
  render() {
    return html`
      <svg
        id="main"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        role=${ifDefined(this.label ? 'img' : undefined)}
        aria-label=${ifDefined(this.label || undefined)}
        aria-hidden=${ifDefined(this.label ? undefined : 'true')}
        style=${styleMap({
      transform: this.rotation ? `rotate(${this.rotation}deg)` : null,
      height: `var(--arc-font-size-${this.size})`,
      width: `var(--arc-font-size-${this.size})`
    })}
      >
        ${PhIconLightning_1.svg}
      </svg>
    `;
  }
};
/** @internal */
PhIconLightning.tag = 'ph-icon-lightning';
/** @internal */
PhIconLightning.styles = styles;
/** @internal */
PhIconLightning.svg = svg`<path d="M215.79,118.17a8,8,0,0,0-5-5.66L153.18,90.9l14.66-73.33a8,8,0,0,0-13.69-7l-112,120a8,8,0,0,0,3,13l57.63,21.61L88.16,238.43a8,8,0,0,0,13.69,7l112-120A8,8,0,0,0,215.79,118.17ZM109.37,214l10.47-52.38a8,8,0,0,0-5-9.06L62,132.71l84.62-90.66L136.16,94.43a8,8,0,0,0,5,9.06l52.8,19.8Z"/>`;
__decorate([property({
  type: String
}), __metadata("design:type", String)], PhIconLightning.prototype, "label", void 0);
__decorate([property({
  type: String,
  reflect: true
}), __metadata("design:type", String)], PhIconLightning.prototype, "size", void 0);
__decorate([property({
  type: Number
}), __metadata("design:type", Number)], PhIconLightning.prototype, "rotation", void 0);
PhIconLightning = PhIconLightning_1 = __decorate([customElement('ph-icon-lightning')], PhIconLightning);
export default PhIconLightning;
